import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Logo } from "./static/repeatTemplate_icon.svg";
import { ReactComponent as ArrowCircle } from "./static/arrowCircle.svg";
import { ReactComponent as Arrow } from "./static/arrow.svg";
import { ReactComponent as Info } from "./static/repeatTemplateInfo.svg";
import DropDown from '../../UIcomponents/DropDown/DropDown';
import ConverterUTC from '../../ConverterUTC/ConverterUTC';
import { useSelector } from 'react-redux';
import ToolTipComponent from '../../UIcomponents/Tooltips/Tooltip';
import { useOutsideClick } from '../../customHooks/useOutsideClick';
import { PayloadDropDown } from '../../UIcomponents/DropDown/dropDownType/dropDownType';
import { CustomListItems, SettingsUserState } from '../../Types/Types';
import InputSelect from '../../UIcomponents/InputSelect/InputSelect';
import Deadlineinput from '../SelectDateCalendar/DeadlineInput/Deadlineinput';
import CustomList from '../../UIcomponents/Lists/CustomList';
import Button from '../../UIcomponents/Button/Button';
import CalendarDotted from '../../UIcomponents/Calendar/Dotted/CalendarDotted';
import ModalWindow from '../../ModalWindow/ModalWindow/ModalWindow';
import DropDownSelectOfDate from '../SelectDateCalendar/SelectDateCalendar';
import OneMonthCalendar from '../../UIcomponents/Calendar/OneMonth/OneMonth';
import { CalendarDottedType } from '../../UIcomponents/Calendar/Dotted/Types/CalendarDottedType';

type repeatTemlateTask = {
    repitPeriod: number,
    templateTime: string,
    intervalType: number,
    interval: 1,
    selectedWeekDays: string | null,
    selectedMonthDays: string | null,
    endType: number,
    dateEnd: string,
    repitCount: number,
    isCreatedAfterCompited: boolean
}

interface DDSelectRepeatTemplateProps {
    updateTemplate: Function,
    templateData: repeatTemlateTask,
    alertNullPeriod: boolean,
    setAlertNullPeriod: Function,
    alertNullPeriodIcon: boolean
    setAlertNullPeriodIcon: Function
    scroll: Function
}

export default function DDSelectRepeatTemplate({ updateTemplate, templateData, alertNullPeriod, setAlertNullPeriod, alertNullPeriodIcon, setAlertNullPeriodIcon, scroll}:DDSelectRepeatTemplateProps) {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [periodIsPicked, setPeriodIsPicked] = useState<boolean>(false);
    const [pickedOption, setPickedOption] = useState<number | null>(null);
    const [detailedConfig, setDetailedConfig] = useState<boolean>(false);
    const settingsUser = useSelector((state: SettingsUserState) => state.settings.settings)
    const [dateNow, setDateNow] = useState<Date>(new Date())
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [isActiveIntervalPick, setIsActiveIntervalPick] = useState<boolean>(false)
    const refComponent = useRef(null)
    const refDetailedConfig = useRef(null)
    const [windowScreen, setWindowScreen] = useState(window.screen.width);
    useOutsideClick(refComponent, () => setIsActive(false), isActive)
    useOutsideClick(refDetailedConfig, () => setDetailedConfig(false), detailedConfig)
    const [arrWeekDays, setArrWeekDays] = useState<CalendarDottedType[]>([])
    const [arrMonthDays, setArrMonthDays] = useState<number[]>([])
    const stylesList = {
        width: windowScreen > 410 ? '303px' : '268px',
        backgroundColor: '#F8C440',
        top: windowScreen > 410 ? '160%' : '75%',
        right: windowScreen > 410 ? null : '25%',
        left: windowScreen > 410 && windowScreen < 992 ? null : windowScreen < 410 ? '0%' : '-100%',
        transform: windowScreen > 410 ? null : 'translateX(20%)'
      }
    const stylesListArrow = {
        top: '-8%',
        left: windowScreen > 410 ? '6%' : null,
        right: windowScreen > 410 ? null : '25.5%'
      }
    const stylesContainer = {
        position: windowScreen > 410 ? null : 'inherit'
    }
    const positionList = {
        right: '0%',
        marginRight: '12px',
        transform: 'translate(0%, 0%)'
    }

      useEffect(() => {
        const handleUpdateScreenWidth = () => {
          setWindowScreen(window.screen.width)
        }
    
        window.addEventListener('resize', handleUpdateScreenWidth);
    
        return () => {
          window.removeEventListener('resize', handleUpdateScreenWidth);
        };
      }, [windowScreen])
      
    useEffect(() => {
        if (settingsUser) {
            setDateNow(ConverterUTC({method: 'object',  settingsUser: settingsUser}) as Date)
        }
    }, [settingsUser])

    const updateIntervalPickedVariant = (payload: PayloadDropDown) => {
        updateTemplate('intervalType', payload.index)
    }


    const updateTemplateEndDate = (payload: string) => {
        updateTemplate('dateEnd', payload)
    }
    

    const handleSetPeriod = (payload: PayloadDropDown) => {
        setPickedOption(payload.index + 1)
        if (payload.index + 1 === 5) {
            setDetailedConfig(true)
        }
        setIsActive(false)
        setPeriodIsPicked(true)
        setAlertNullPeriod(false)
        setAlertNullPeriodIcon(false)
        updateTemplate('repitPeriod', payload.index + 1)
    }
    
    function checkWords(e: any, type: string) {
        if ("0123456789".indexOf(e.key) !== -1) {
            logInput(e, type)
        } else {
            e.preventDefault()
        }
    }

    const logInput = (e: any, type: string) => {
        if (e.target.value === '0') {
            updateTemplate(`${type}`, 1)
        } else if (e.target.value.length > 3) {
            e.preventDefault()
        } else {
            updateTemplate(`${type}`, e.target.value)
        }
    }

    useEffect(() => {
        if (alertNullPeriod) {
            scroll()
        }
        // eslint-disable-next-line 
    }, [alertNullPeriod])
    
    const updateTimeTask = (payload: string) => {
        updateTemplate('templateTime', payload)
    }
    
    const updateEndTypeVariant = (variant: number) => {
        switch (variant) {
            case 0:
                updateTemplate('repitCount', 0)
                updateTemplate('dateEnd', '')
                break;
            case 1:
                updateTemplate('repitCount', 0)
                updateTemplate('dateEnd', dateNow.toLocaleDateString())
                break;
            case 2:
                updateTemplate('dateEnd', '')
                break;
            default:
                break;
        }
        updateTemplate('endType', variant)
    }

    const handleSelectedWeekDays = (weekDays: CalendarDottedType[]) => {
        setArrWeekDays(weekDays)
    }

    const handleSelectedMonthDays = (monthDays: number[]) => {
        setArrMonthDays(monthDays)
    }

    const declineChanges = () => {
        updateTemplate('repitPeriod', null)
        updateTemplate('templateTime', '')
        updateTemplate('intervalType', 0)
        updateTemplate('interval', 1)
        updateTemplate('selectedWeekDays', null)
        updateTemplate('selectedMonthDays', null)
        updateTemplate('endType', 0)
        updateTemplate('dateEnd', '')
        updateTemplate('repitCount', 1)
        updateTemplate('isCreatedAfterCompited', false)
        setDetailedConfig(false)
    }

    useEffect(() => {        
        const getDaysId = arrWeekDays.map(el => el.id).join(', ')        
        updateTemplate('selectedWeekDays', getDaysId)
        // eslint-disable-next-line
    }, [arrWeekDays])

    useEffect (() => {
        const toString = arrMonthDays.join(', ')
        updateTemplate('selectedMonthDays', toString)
        // eslint-disable-next-line
    }, [arrMonthDays])
    
    const generateInfo = () => {
        if (templateData.interval <= 1) {
            if (templateData.intervalType === 0) {
                return `Каждый день`
            }
            if (templateData.intervalType === 1) {
                return `Каждую неделю`
            }
            if (templateData.intervalType === 2) {
                return `Каждый месяц`
            }
            if (templateData.intervalType === 3) {
                return `Каждый год`
            } 
        }
        if (templateData.interval%10 === 1 && templateData.interval%100 !== 11) {
            if (templateData.intervalType === 0) {
                return `Каждый ${templateData.interval} день`
            }
            if (templateData.intervalType === 1) {
                return `Каждую ${templateData.interval} неделю`
            }
            if (templateData.intervalType === 2) {
                return `Каждый ${templateData.interval} месяц`
            }
            if (templateData.intervalType === 3) {
                return `Каждый ${templateData.interval} год`
            } 
        }else if ((templateData.interval%10 === 2 || templateData.interval%10 === 3 || templateData.interval%10 === 4) &&
            (templateData.interval%100 !== 12 && templateData.interval%100 !== 13 && templateData.interval%100 !== 14)) {
            if (templateData.intervalType === 0) {
                return `Каждые ${templateData.interval} дня`
            }
            if (templateData.intervalType === 1) {
                return `Каждые ${templateData.interval} недели`
            }
            if (templateData.intervalType === 2) {
                return `Каждые ${templateData.interval} месяца`
            }
            if (templateData.intervalType === 3) {
                return `Каждые ${templateData.interval} года`
            }
        } else {
            if (templateData.intervalType === 0) {
                return `Каждые ${templateData.interval} дней`
            }
            if (templateData.intervalType === 1) {
                return `Каждые ${templateData.interval} недель`
            }
            if (templateData.intervalType === 2) {
                return `Каждые ${templateData.interval} месяцев`
            }
            if (templateData.intervalType === 3) {
                return `Каждые ${templateData.interval} лет`
            }
        }
    }

    useEffect(() => {
        generateInfo()
        // eslint-disable-next-line
    }, [templateData])
    
    const arrList: CustomListItems[] = [{id: 'never', description: 'Никогда'}, 
    {id: 'date', description: 'Дата', element: <DropDownSelectOfDate getDatePayload={updateTemplateEndDate} />}, 
    {id: 'after', description:'После', element: <><input className='customList__input-container__input' type="number" value={templateData.repitCount} onKeyPress={e => checkWords(e, 'repitCount')} onChange={(e: any) => logInput(e, 'repitCount')} />  раз</>}];
    const arrList2: CustomListItems[] = [{id: 'createRepeat', description: 'Создавать только после выполнения'}];
    const arrOptionDays: string[] = ['Каждое воскресенье', 'Каждый понедельник', 'Каждый вторник', 'Каждую среду', 'Каждый четверг', 'Каждую пятницу', 'Каждую субботу']
    const arrOptionIntervals: string[] = ['День', 'Неделя', 'Месяц', 'Год']
    const optionArr: string[] = ['Каждый день', arrOptionDays[dateNow.getDay()], 'Ежемесячно', 'По будням', 'Хочу по-другому']
    return (
        <div className='repeatTemplate'>
            <ToolTipComponent autoOpen={alertNullPeriod} active={false} stylesContainer={stylesContainer} stylesList={stylesList} stylesListArrow={stylesListArrow} tooltipHeader='Выберите период повторения' tooltipDescription='Иначе не получится поставить задачу'>
                <div className={alertNullPeriodIcon ? 'repeatTemplate__btn alert_template' : periodIsPicked ? 'repeatTemplate__btn active_template' : 'repeatTemplate__btn'} title={alertNullPeriod ? '' : 'Повторить задачу'} onClick={(e) => {e.stopPropagation()
                    setIsActive(!isActive)}}>
                    <Logo className={isActive || periodIsPicked ? 'repeatTemplate__btn__icon active__btn' : 'repeatTemplate__btn__icon'}/>
                    <p className='repeatTemplate__btn-descr'>{pickedOption !== null ? optionArr[pickedOption - 1] : 'Повторять'}</p>
                </div>
            </ToolTipComponent>
            <DropDown onClickFunc={handleSetPeriod} position={windowScreen > 410 ? {} : positionList} isActive={isActive} updateIsActive={setIsActive} optionArr={optionArr} defaultValue={pickedOption! - 1}/>
            <ModalWindow dropPaddings={true} disabledCloseBtn={true} position={'center'} modalShow={detailedConfig} updateModalState={() => setDetailedConfig(false)}>
                <div className='detailConfig' ref={refDetailedConfig}>
                    <div className='detailConfig__container' style={{display: "inherit", flexDirection: 'column', gap: '20px', justifyContent: 'center'}}>
                        <div className='detailConfig__heading'>
                            <ArrowCircle />
                            <p>Повторять задачу</p>
                        </div>
                        <div style={{display: 'inherit', flexDirection: 'column', gap: '20px', height:'auto', transition: 'all ease-in 0.3s'}}>
                            <div className='detailConfig__intervalCount'>
                                <p>Повторять с интервалом</p>
                                    <div className='detailConfig__intervalCount__input'>
                                        <input maxLength={3} 
                                            type="number" 
                                            value={templateData.interval}  
                                            onBlur={e => e.target.value === '' ? updateTemplate('interval', 1) : null} 
                                            onKeyPress={e => checkWords(e, 'interval')} 
                                            onChange={(e: any) => logInput(e, 'interval')}/>
                                    </div>
                                <InputSelect onClickFunc={() => setIsActiveIntervalPick(!isActiveIntervalPick)} isActiveChildren={isActiveIntervalPick} title={arrOptionIntervals[templateData.intervalType]}>
                                    <DropDown defaultValue={templateData.intervalType} isActive={isActiveIntervalPick} updateIsActive={setIsActiveIntervalPick} onClickFunc={updateIntervalPickedVariant} optionArr={arrOptionIntervals}/>
                                </InputSelect>
                            </div>
                            {
                                templateData?.intervalType === 1 && 
                                <div className={`detailConfig__calendarDotted expanded`}>
                                    <CalendarDotted onSelectedChange={handleSelectedWeekDays}/>
                                </div>
                            }
                            {
                                templateData?.intervalType === 2 && 
                                <div style={{display:'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}} className={`detailConfig_classicCalendar expanded`}>
                                    <OneMonthCalendar onSelectedChange={handleSelectedMonthDays}/>
                                </div> 
                            }
                            <div className='detailConfig__intervalTime'>
                                <p>Время</p>
                                { detailedConfig && 
                                <Deadlineinput onChangeFunc={updateTimeTask}/> } 
                            </div>
                            <div className='deatilConfig__otherParams'>
                                <div className='detailConfig__otherParamsContainer' onClick={() => setIsDisabled(!isDisabled)} style={ isDisabled ? {color: '#6B7280'} : {color: '#232360'}}>Другие параметры <Arrow className='' style={isDisabled ? {transform: 'rotateZ(90deg)' } : {transform: 'rotateZ(0deg)'}}/></div>
                    
                                <p style={isDisabled ? {display: 'none'} : {display: 'block', color: '#6B7280', fontSize: '12px', lineHeight: '16px'}}>Окончание</p>
                                <CustomList defaultCheck={templateData.endType} 
                                    onChangeFunc={(index: number) => updateEndTypeVariant(index)} 
                                    list={arrList} 
                                    listName='list' 
                                    hide={isDisabled} 
                                    listVariant="radio" />
                                <CustomList onChangeFunc={(isCheck: boolean) => updateTemplate('isCreatedAfterCompited', isCheck)} 
                                    list={arrList2}
                                    listName='list'  
                                    listVariant="checkbox" 
                                    hide={isDisabled}/>                              
                            </div>
                                <div className='detailConfig__resultContainer'>
                                    <Info />
                                    <p>{generateInfo()} {arrWeekDays.length > 0 && `в ${arrWeekDays.map(el => {return el.name})}`} {templateData.templateTime && `в ${templateData.templateTime}`}</p>
                                    <p>{templateData.selectedMonthDays === '0' ? 'в последний день месяца' : templateData.selectedMonthDays}</p>
                                </div>
                            
                        </div>
                            <div style={{display: 'flex', gap: '12px', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <Button title='Отмена' btnType="default" btnSize="base" onClickFunc={() => declineChanges()}/>
                                <Button title='Повторять' btnType="filled" btnSize="base" onClickFunc={() => setDetailedConfig(false)} />
                            </div>
                    </div>
                </div>
            </ModalWindow>
        </div>
    )
}
    
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import ConverterUTC from "../../ConverterUTC/ConverterUTC";
import { SettingsUserState } from "../../Types/Types";
import './SelectDateCalendar.css';
import Deadlineinput from "./DeadlineInput/Deadlineinput";
import { useOutsideClick } from "../../customHooks/useOutsideClick";
import TaskCalendar from "../../UIcomponents/Calendar/Base/Calendar";
import { ReactComponent as CalendarPic } from "../../icons/svg/ModalChangingTask/calendar.svg"
import ChangePeriodDateButtons from "./ChangePeriodDateButtons/ChangePeriodDateButtons";

interface SelecOfDateProps {
    addTaskButtons?: boolean,
    deadlineinput?: boolean, 
    statisticsButton?: boolean,
    time?: string,
    disabledItem?: boolean,
    pushDataTime?: Function,
    getDatePayload: Function
}

export default function DropDownSelectOfDate({addTaskButtons, 
    deadlineinput, 
    statisticsButton,
    time,
    pushDataTime,
    getDatePayload}: SelecOfDateProps) {
    const [isOpenDD, setIsOpenDD] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<Date[]>([new Date() , new Date()])
    const [pickedDate, setPickedDate] = useState<Date | null>(new Date());
    const [currentPeriod, setCurrentPeriod] = useState<string>('today')
    const settingsUser = useSelector((state: SettingsUserState) => state.settings.settings);
    const dateToday = ConverterUTC({method: 'object', settingsUser: settingsUser})
    const dateTomorrow = new Date();
    dateTomorrow.setDate(new Date().getDate() + 1)
    const refSelectOfDate = useRef<null | HTMLDivElement>(null)
    const resetDeadlineRef = useRef<any>(null)
    const daysOfWeek = [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота'
    ]

    if (!(dateToday instanceof Date)) {
        throw new Error("dateToday должен быть экземпляром Date");
    }

    // useEffect(() => {
    //     if (pickedDate?.toLocaleDateString() === dateToday.toLocaleDateString()) {
    //         setCurrentPeriod('today')
    //     } else if (statisticsButton && dateRange) {
    //         if (dateRange[0].toLocaleDateString() === dateToday.toLocaleDateString() &&
    //             dateRange[1].toLocaleDateString() === dateToday.toLocaleDateString()) {
    //             setCurrentPeriod('onToday')
    //         }
    //     } else {
    //         setCurrentPeriod('')
    //     }
    // }, [pickedDate])

    
    useOutsideClick(refSelectOfDate, () => setIsOpenDD(false), isOpenDD)
                                                    
    const handleIsOpen = () => {
        setIsOpenDD(!isOpenDD)
    }


    const checkValueInputDate = (value: Date | null, time?: string) => {
        if (!(dateToday instanceof Date)) {
            throw new Error("dateToday должен быть экземпляром Date");
        }

        const toZeroHoursToday = dateToday.setHours(0,0,0,0)
        const toZeroHoursTomorrow = dateTomorrow.setHours(0,0,0,0)
        
        const toNormalizeDate = (date: string) => {
            if (date === '') {
                return date
            } else {
                const parts = date.split('.');
                const day = parseInt(parts[0], 10);  
                const month = parseInt(parts[1], 10) - 1; 
                const year = parseInt(parts[2], 10);
                return new Date(year, month, day);
            }
        }

        const getDayOfDate = (value: Date | null) => {
            if (value === null) {
                return 'Без срока'
            } else if (value.setHours(0,0,0,0) === toZeroHoursToday) {
                return 'Сегодня'
            } else if (value.setHours(0,0,0,0) === toZeroHoursTomorrow) {
                return 'Завтра'
            } else {
                const firstDayOfWeek = new Date()
                let getfirst = new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() - firstDayOfWeek.getDay() + 1))
                const lastDayOfWeek = new Date()
                let getlast = new Date(lastDayOfWeek.setDate(lastDayOfWeek.getDate() - lastDayOfWeek.getDay() + 7))
                if (toNormalizeDate(value.toLocaleDateString() ?? '') >= getfirst && toNormalizeDate(value.toLocaleDateString() ?? '') <= getlast) {
                    return daysOfWeek[pickedDate!.getDay()]
                } else {
                    return value.toLocaleDateString()
                }
            } 
        }

        const getTimeOfDate = (time: string) => {
            if (time === '') {
                return ''
            } else {
                return time
            }
        }
        
        return getDayOfDate(value) + ' ' + getTimeOfDate(time ?? '')
    }
    
    const getDate = (payload: Date) => {
        const getPeriod = () => {
            if (payload.toLocaleDateString() === dateToday.toLocaleDateString()) {
                return 'today'
            } else if (payload.toLocaleDateString() === dateTomorrow.toLocaleDateString()) {
                return 'tomorrow'
            } else  {
                return ''
            }
        }      
        updateDataDate({date: payload, period: getPeriod()})
    }

    const getRange = (payload: Date[]) => {
        updateDataRange({arr: payload, period: ''})
    }

    const updateDataDate = (payload: {date: Date | null, period: string}) => {        
        setPickedDate(payload.date ?? null)
        setCurrentPeriod(payload.period)
        if (getDatePayload) {
            getDatePayload(payload.date?.toLocaleDateString() ?? '')
        }
        if (payload.period === 'no_date') {
            resetDeadlineRef.current.reset()
            if (pushDataTime) {
                pushDataTime('')
            }
        }
    }

    const updateDataRange = (payload: {arr: Date[], period: string}) => {
        setCurrentPeriod(payload.period)        
        setDateRange(payload.arr)
        // if (getDatePayload) {
        //     getDatePayload(payload?.toLocaleDateString() ?? '')
        // }
    }
    
    const updateTime = (time: string) => {
        if (currentPeriod === 'no_date') {
            updateDataDate({date: dateToday, period: 'today'})
        }
        if (pushDataTime) {
            pushDataTime(time)
        } 
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            <div className={`DatePicker ${isOpenDD ? 'active' : ''}`} tabIndex={0}>
                <CalendarPic className="DatePicker-icon"/>
                <span className='DatePicker-container' onClick={(e: any) => {
                    e.stopPropagation()
                    handleIsOpen()}}>
                    <p className="DatePicker-container__title">{checkValueInputDate(pickedDate ?? null, time ?? '')}</p>
                </span>
            </div>
                    {
                        isOpenDD &&
                        <div className="DatePicker-calendar">
                            <div className="DatePicker-calendar__container"/>
                            <div className="DatePicker-calendar__Calendar" style={{}}
                                ref={refSelectOfDate}>
                                {/* <ul className='calendar__list'> */}
                                <ul className="DatePicker-calendar__Calendar-list">
                                {
                                    statisticsButton && 
                                    <ChangePeriodDateButtons buttonsVariant="statistic" 
                                        currentDate={dateToday} 
                                        pickedPeriod={currentPeriod} 
                                        onChangePeriod={updateDataRange} /> 
                                }
                                {
                                    addTaskButtons &&
                                    <ChangePeriodDateButtons buttonsVariant="addTask" 
                                        currentDate={dateToday} 
                                        pickedPeriod={currentPeriod} 
                                        onChangePeriod={updateDataDate} /> 
                                }
                                {
                                    deadlineinput && 
                                    <Deadlineinput ref={resetDeadlineRef}
                                        onChangeFunc={updateTime}
                                        showLogo={true}
                                    />
                                }
                                <TaskCalendar 
                                    onCloseCalendar={setIsOpenDD} 
                                    datePayload={pickedDate}
                                    initialRange={dateRange}
                                    isRangeMode={statisticsButton ?? false}
                                    isPreviosDateAllow={statisticsButton ?? false} 
                                    onSelectDay={getDate} 
                                    onSelectRange={getRange}/>
                                </ul>
                            </div>
                        </div>
                    }
        </div>
    )
}
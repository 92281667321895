import React, { useEffect, useState } from "react";
import getMonthData from "./helpers/getMonthData";
import { CalendarType } from "./Types/CalendarType";
import InputSelect from "../../InputSelect/InputSelect";
import DropDown from "../../DropDown/DropDown";
import { PayloadDropDown } from "../../DropDown/dropDownType/dropDownType";
import './Calendar.css';

interface CalendarPayload extends CalendarType{}

const TaskCalendar: React.FC<CalendarPayload> = ({
    initialRange = [],
    calendarVariant = 'plates',
    currentDate  = new Date(),
    datePayload = new Date(),
    isRangeMode = false,
    isPreviosDateAllow = false,
    showWeekend = false,
    onSelectDay,
    onCloseCalendar,
    onSelectRange
}) => {
    const [date, setDate] = useState<Date>(new Date());
    const monthNames = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    const weekDayNames = ['Пн','Вт','Ср','Чт','Пт','Сб','Вс'];
    const monthDate = getMonthData(date.getFullYear(), date.getMonth())
    const years: string[] = [];
    const datePrevious = new Date(date?.getFullYear(), date?.getMonth() - 1);
    const dateNext = new Date(date?.getFullYear(), date?.getMonth() + 1);
    const [selectedMonth, setSelectedMonth] = useState<number>(date.getMonth())
    const [selectedYear, setSelectedYear] = useState<number>(date.getFullYear())
    const [selectedDay, setSelectedDay] = useState<Date | null>(datePayload);
    const [selectedRange, setSelectedRange] = useState<Date[]>(initialRange);
    const [isActiveMonthPicker, setIsActiveMonthPicker] = useState(false)
    const [isActiveYearPicker, setIsActiveYearPicker] = useState(false)
    const [helperRange, setHelperRange] = useState(false)
       
    useEffect(() => {
        if (datePayload) {
            setSelectedDay(datePayload)
        }
    }, [datePayload])

    useEffect(() => {
        if (initialRange) {
            setSelectedRange(initialRange)
        }
    }, [initialRange])

    if (!(currentDate instanceof Date)) {
        throw new Error("dateToday должен быть экземпляром Date");
    }

    for (let i = currentDate?.getFullYear(); i <= currentDate?.getFullYear() + 8; i++) {
        years.push(`${i}`);
    }

    const StyleDD = {
        height: '250px'
    }

    const handleSelectDay = (selectedDay: Date) => {
        if (isRangeMode) {
            if (selectedRange.length === 2) {
                setSelectedRange([selectedDay])
            } else {
                setSelectedRange((prevState) => [...prevState, selectedDay])
            }
        } else {
            setSelectedDay(selectedDay)
            if (onSelectDay) {
                onSelectDay(selectedDay)
            }
            if (onCloseCalendar) {
                onCloseCalendar(false)
            }
        }
    }

    const inRange = (value: Date) => {
        for (let i = 0; i < selectedRange.length; i++) {
            if (selectedRange[i].getDate() === value.getDate() &&
                selectedRange[i].getMonth() === value.getMonth() && 
                selectedRange[i].getFullYear() === value.getFullYear()) {                    
                return 'selectedDay'
            }                   
        }    
        if (selectedRange.length === 2) {          
            if (((value.getTime() > selectedRange[0].getTime()) && 
                (value.getTime() < selectedRange[1].getTime())) || 
                ((value.getTime() > selectedRange[1].getTime()) && 
                (value.getTime() < selectedRange[0].getTime()))) {
                return 'ranged';
            } else {
                return ''
            }
            
        } else {
            return ''
        }
    }

    const updateCalendarData = (keyMonth: number, keyYear: number) => {
        let year = keyYear;
        let month = keyMonth;
        setSelectedMonth(keyMonth);
        setSelectedYear(keyYear);
        const date = new Date(year, month);
        setDate(date);
    }

    const updateMonth = (payload: PayloadDropDown) => {
        setIsActiveMonthPicker(false)        
        setSelectedMonth(payload.index)
        updateCalendarData(payload.index, selectedYear)
    }
    
    const updateYear = (payload: PayloadDropDown) => {
        setIsActiveYearPicker(false)
        setSelectedYear(payload.element as number)
        updateCalendarData(selectedMonth, payload.element as number)
    }

    useEffect(() => {
        setSelectedMonth(date.getMonth())
        setSelectedYear(date.getFullYear())
    }, [date])

    useEffect(() => {
        if (onSelectRange) {
            onSelectRange(selectedRange)
        }
        // eslint-disable-next-line
    }, [selectedRange])

    const dateСomparison = (dateOfMonth: Date) => {
        if (isPreviosDateAllow) {
            return ''
        }
        let date1 = Date.UTC(dateOfMonth.getFullYear(), dateOfMonth.getMonth(), dateOfMonth.getDate())
        let date2 = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        if (date1 < date2) {
            if (showWeekend) {
                return 'pastDay-showWeekend'
            } else {
                return 'pastDay'
            }
        } else {
            return ''
        }
    }

    const colorSelectedDay = (day: Date) => {
        if (isRangeMode) {
            return inRange(day)
        } else {
            if (selectedDay?.getDate() === day.getDate() && 
                selectedDay?.getMonth() === day.getMonth() && 
                selectedDay?.getFullYear() === day.getFullYear()) {
                return 'selectedDay'
            } else {
                return ''
            }
        }
    }    

    return(
        <div className="Calendar" style={isRangeMode ? {gap: '6px'} : {gap: '30px'}}>
            <div className="Calendar-Navi">
                <div className={`Calendar-Navi__previousMonth ${!isPreviosDateAllow && datePrevious.getMonth() < currentDate.getMonth() && 
                        datePrevious.getFullYear() <= currentDate.getFullYear() ? 'pastMonth' : ''}`} 
                        onClick={() => setDate(datePrevious)}>
                    <div className="Calendar-Navi__previousMonth-arrow" />
                </div>
                <div className="Calendar-Navi__currentData">
                    {
                        !isRangeMode &&
                        <p className="Calendar-Navi_currentData-p">{selectedDay ? selectedDay.getDate() : date.getDate()}</p>
                    }
                    <ul className="Calendar-Navi__currentData-list">
                        <li className="Calendar-Navi__currentData-list__item">
                            <InputSelect title={monthNames[selectedMonth]}
                                disabledStyles={true}
                                isActiveChildren={isActiveMonthPicker} 
                                onClickFunc={() => {setIsActiveMonthPicker(!isActiveMonthPicker)
                                setIsActiveYearPicker(false)}}>
                                {   
                                    calendarVariant === 'dropdowns' ?
                                    <DropDown isActive={isActiveMonthPicker} 
                                        updateIsActive={setIsActiveMonthPicker} 
                                        defaultValue={monthNames[selectedMonth]} 
                                        onClickFunc={updateMonth} 
                                        optionArr={monthNames}
                                        position={StyleDD}
                                    /> : <></>
                                }
                            </InputSelect>
                        </li>
                        <li className="Calendar-Navi__currentData-list__item">
                        <InputSelect title={`${selectedYear}`}
                                disabledStyles={true}
                                isActiveChildren={isActiveYearPicker} 
                                onClickFunc={() => {setIsActiveYearPicker(!isActiveYearPicker)
                                setIsActiveMonthPicker(false)}}>
                                {
                                    calendarVariant === 'dropdowns' ? 
                                    <DropDown isActive={isActiveYearPicker} 
                                        updateIsActive={setIsActiveYearPicker} 
                                        defaultValue={`${selectedYear}`} 
                                        onClickFunc={updateYear} 
                                        optionArr={years}
                                        position={StyleDD}
                                    /> : <></>
                                }
                            </InputSelect>
                        </li>
                    </ul>
                </div>
                <div className="Calendar-Nave__nextMonth" onClick={() => setDate(dateNext)}>
                <div className="Calendar-Navi__nextMonth-arrow" />
                </div>
            </div>
            {
                        isRangeMode && 
                        <div style={{display: 'flex'}}>
                            <p className={`Calendar-rangeDescription ${selectedRange.length > 0 && 'active'}`}>{selectedRange.length === 2 ? 
                                `${selectedRange[0].toLocaleDateString()} - ${selectedRange[1].toLocaleDateString()}` : selectedRange.length === 1 ?
                                `${selectedRange[0].toLocaleDateString()}` : ''}
                            </p>
                        </div>
                    }
            {
                isActiveMonthPicker && calendarVariant === 'plates' ? 
                <div className={`monthNamesPlates`} key={'month'}>
                    {
                        monthNames.map((el, index) => {
                            return (
                                <div>
                                    <span className={`monthNamesPlates__span ${(selectedMonth === index ? 'currentSelect' : 
                                    new Date().getMonth() === index ? 'current' : '') || (currentDate.getMonth() > index && !isPreviosDateAllow ? 'pastMonth' : '')}`} 
                                        key={index} 
                                        onClick={(e) => {e.stopPropagation()
                                                        updateMonth({element: el, index: index})}}>
                                            {el}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div> : isActiveYearPicker && calendarVariant === 'plates' ?
                <div className={`monthNamesPlates`} key={'years'}>
                    {
                        years.map((el, index) => {
                            return (
                                <div>
                                    <span  className={`monthNamesPlates__span ${`${selectedYear}` === el ? 'currentSelect' : `${new Date().getFullYear()}` === el ? 'current' : ''}`}
                                        key={index}
                                        onClick={(e) => { e.stopPropagation()
                                                            updateYear({element: el, index: index}) }}>
                                            {el}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div> :
                <>
                    <table className="Calendar-table">
                        <thead className="Calendar-table__thead">
                            <tr className="Calendar-table__thead-tr">
                                {weekDayNames.map((name) =>
                                    <th className={`Calendar-table__thead-tr-th ${showWeekend && 'showWeekend'}`} key={name}>
                                            {name}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="Calendar-table__tbody" key={`${date}`}>
                            {monthDate?.map((week, index) =>
                                <tr className="Calendar-table__tbody-tr" key={index}>
                                    {week.map((data: Date, index: number) => data ?
                                    <td className={`Calendar-table__tbody-tr-td ${dateСomparison(data)} ${showWeekend && 'showWeekend'}` } key={index} 
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (isRangeMode) {
                                                if (helperRange) {
                                                setHelperRange(false)   
                                                } else {
                                                    handleSelectDay(data)
                                                    setHelperRange(true)}
                                            } else {
                                                handleSelectDay(data)
                                            }}}
                                        onMouseEnter={() => isRangeMode && selectedRange.length === 1 && helperRange ? handleSelectDay(data) : null}
                                        onMouseLeave={() => isRangeMode && selectedRange.length === 2 && helperRange ? selectedRange.pop() : null }
                                        >
                                        <p className={`${currentDate.toLocaleDateString() === data.toLocaleDateString() ? 'currentDay' : ''} ${colorSelectedDay(data)}`}
                                        >{data.getDate()}</p>    
                                    </td>
                                    :
                                    <td className="Calendar-table__tbody-tr-td" key={index}/>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table> 
                </>
            }
        </div>
    )
};

export default TaskCalendar;
import React from "react"
import { ReactElement } from "react"


export type picElement = {
    elem: ReactElement,
    theme: string,
    description?: string
}

export const picturesArray: picElement[] = [
    {elem: <img width="32" height="32" src="https://img.icons8.com/color/48/christmas.png" alt="christmas"/>,
        theme: 'cristmas'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/color/48/santa-sits-under-christmas-tree.png" alt="santa-sits-under-christmas-tree"/>,
        theme: 'cristmas'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/external-victoruler-flat-victoruler/64/external-christmas-tree-christmas-victoruler-flat-victoruler-1.png" alt="external-christmas-tree-christmas-victoruler-flat-victoruler-1"/>,
        theme: 'cristmas'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/external-flat-icons-pause-08/64/external-christmas-winter-flat-icons-pause-08-7.png" alt="external-christmas-winter-flat-icons-pause-08-7"/>,
        theme: 'cristmas'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/external-others-mangsaabguru-/64/external-christmas-christmas-flat-others-mangsaabguru--7.png" alt="external-christmas-christmas-flat-others-mangsaabguru--7"/>,
        theme: 'cristmas'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/external-others-mangsaabguru-/64/external-christmas-christmas-flat-others-mangsaabguru--6.png" alt="external-christmas-christmas-flat-others-mangsaabguru--6"/>,
        theme: 'cristmas'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/external-smashingstocks-flat-smashing-stocks/66/external-Santa-Claus-christmas-smashingstocks-flat-smashing-stocks-6.png" alt="external-Santa-Claus-christmas-smashingstocks-flat-smashing-stocks-6"/>,
        theme: 'cristmas'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/color/48/lantern.png" alt="lantern"/>,
        theme: 'chinaNewYear'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/emoji/48/tulip-emoji.png" alt="tulip-emoji"/>,
        theme: 'womansDay'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/color/48/earth-planet--v2.png" alt="earth-planet"/>,
        theme: 'earthDay'},
    {elem: <img width="32" height="32" src="https://img.icons8.com/officel/40/data-backup.png" alt="data-backup"/>,
        theme: 'backupDay'}
]
import React, { useEffect, useState } from "react"
import { picElement, picturesArray } from "./data/exportsPic"

type holidaysArr = {
    holidayName: string,
    startHoliday: Date,
    endHoliday: Date
}

interface EPGProps {
    date: Date
}

export default function EPG({ date }: EPGProps) {
    const currentDate = new Date();
    const [randomSVG, setRandomSVG] = useState<picElement | null>(null);
    const holidays: holidaysArr[] = [
        {holidayName: 'cristmas', startHoliday: new Date(`${currentDate.getFullYear()}-12-21`), endHoliday: new Date(`${currentDate.getFullYear() + 1}-01-21`)},
        {holidayName: 'chinaNewYear', startHoliday: new Date(`${currentDate.getFullYear()}-01-26`), endHoliday: new Date(`${currentDate.getFullYear()}-02-12`)},
        {holidayName: 'womansDay', startHoliday: new Date(`${currentDate.getFullYear()}-03-08`), endHoliday: new Date(`${currentDate.getFullYear()}-03-08`)},
        {holidayName: 'earthDay', startHoliday: new Date(`${currentDate.getFullYear()}-04-22`), endHoliday: new Date(`${currentDate.getFullYear()}-04-22`)},
        {holidayName: 'backupDay', startHoliday: new Date(`${currentDate.getFullYear()}-03-31`), endHoliday: new Date(`${currentDate.getFullYear()}-03-31`)}
    ]

    function checkDay(date: Date) {        
        holidays.forEach(el => {
            if (date.toLocaleDateString() >= el.startHoliday.toLocaleDateString() && 
                date.toLocaleDateString() <= el.endHoliday.toLocaleDateString()) {
                let currentHolidayArrayPic = picturesArray.filter(pic => pic.theme === el.holidayName)
                let rand = Math.floor(Math.random() * currentHolidayArrayPic.length)
                setRandomSVG(currentHolidayArrayPic[rand])
            }
        })
    }

    useEffect(() => {
        checkDay(date)
        // eslint-disable-next-line
    }, [date])
      
    return (
        <>
            {randomSVG && 
                <div className='header__logo' style={{marginLeft: '12px'}} title={randomSVG?.description}>
                    {randomSVG.elem}
                </div>
            }
        </>
    )
}
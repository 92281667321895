import React, { useState } from "react";
import Button from "../../Button/Button";
import './OneMonth.css';
import { monthArr } from "./Types/CalendarOneMonthType";

interface OneMonthCalendarProps {
    onSelectedChange?: (selectedMonthDays: number[]) => void
}

function generateMont() {
    const arrMonth: monthArr[] = []
    for (let i = 1; i <= 31; i++) {
        arrMonth.push({day: i, isSelected: false})
    }
    return arrMonth
}

export default function OneMonthCalendar({ onSelectedChange }: OneMonthCalendarProps) {
    const [arrMonthDays, setArrMonthDays] = useState<monthArr[]>(generateMont() || []);
    const [isPicked, setIsPicked] = useState<boolean>(false);
    
    const handleOnChangeDay = (monthDay: number) => {
        setArrMonthDays((prevState: monthArr[]) => {
            const newState = prevState.map((el) => 
            el.day === monthDay ? {...el, isSelected: !el.isSelected} : el);
            onSelectedChange && onSelectedChange(newState.filter(el => el.isSelected).map(el => el.day))
            return newState
        })
        if (isPicked) {
            setIsPicked(false)
        }
    }

    const handleChangeLastDay = () => {
        if (arrMonthDays.length > 0) {
            setArrMonthDays((prevState: monthArr[]) => {
                const newState = prevState.map((el) => 
                el.isSelected === true ? {...el, isSelected: !el.isSelected} : el);
                return newState
            })
            onSelectedChange && onSelectedChange([0])
            setIsPicked(true)
        } 
        if (isPicked) {
            onSelectedChange && onSelectedChange([])
            setIsPicked(false)
        }
    }

    return (
        <table className="tableContainer">
            <tbody>
                <tr>
                    {
                        arrMonthDays.map((el, index) => {
                            if ((el.day >= 0) && (el.day <= 7)) {
                                return (
                                    <td onClick={() => handleOnChangeDay(el.day)} className={`tableContainer__td ${el.isSelected && `selected`}`} key={index}>
                                        <div>{el.day}</div>
                                    </td>
                                )
                            }
                            return null
                        })
                    }
                </tr>
                <tr>
                    {
                        arrMonthDays.map((el, index) => {
                            if ((el.day >= 8) && (el.day <= 14)) {
                                return (
                                    <td onClick={() => handleOnChangeDay(el.day)} className={`tableContainer__td ${el.isSelected && `selected`}`} key={index}>
                                        <div>{el.day}</div>
                                    </td>
                                )
                            }
                            return null
                        })
                    }
                </tr>
                <tr>
                    {
                        arrMonthDays.map((el, index) => {
                            if ((el.day >= 15) && (el.day <= 21)) {
                                return (
                                    <td onClick={() => handleOnChangeDay(el.day)} className={`tableContainer__td ${el.isSelected && `selected`}`} key={index}>
                                        <div>{el.day}</div>
                                    </td>
                                )
                            }
                            return null
                        })
                    }
                </tr>
                <tr>
                    {
                        arrMonthDays.map((el, index) => {
                            if ((el.day >= 22) && (el.day <= 28)) {
                                return (
                                    <td onClick={() => handleOnChangeDay(el.day)} className={`tableContainer__td ${el.isSelected && `selected`}`} key={index}>
                                        <div>{el.day}</div>
                                    </td>
                                )
                            }
                            return null
                        })
                    }
                </tr>
                <tr>
                    {
                        arrMonthDays.map((el, index) => {
                            if ((el.day >= 29) && (el.day <= 31)) {
                                return (
                                    <td onClick={() => handleOnChangeDay(el.day)} className={`tableContainer__td ${el.isSelected && `selected`}`} key={index}>
                                        <div>{el.day}</div>
                                    </td>
                                )
                            }
                            return null
                        })
                    }
                    <td colSpan={4} className="tableContainer__td-button">
                        <div>
                            <Button showPicked={isPicked} btnType="default" btnSize="xs" title="последний день месяца" onClickFunc={handleChangeLastDay} />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
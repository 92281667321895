import { useEffect, useState } from 'react';
import Calendar from '../../Calendar/index';
import { currentDateContext } from "../../Context/currentDateContext";
import { currentDateRangeContext } from "../../Context/currentDateRangeContext";
import './index.css';
import Deadlineinput from './DeadlineInput/Deadlineinput';
import AddTaskButtons from './AddTaskButtons/AddTaskButtons';
import ChangePeriodDateButtons from './ChangePeriodDateButtons/ChangePeriodDateButtons';
import ConverterUTC from '../../ConverterUTC/ConverterUTC';
import { useSelector } from 'react-redux';

const DDSelectDate = ({smartRecognition, 
    addTaskButtons, 
    deadlineinput, 
    statisticsButton, 
    active,
    time,
    dataTask,
    datePayload,
    setActive,
    updateDataDate,
    updateDataTime,
    onChangePeriodOfStatistic,
    modalShow,
    typeOfCalendar,
    currentRange,
    setCurrentRange,
    updateSelectedDataRange,
    stylesCalendar,
    stylesCalendarMonthDate,
    stylesCalendarNavi,
    stylesCalendarTable,
    calendarPosition,
    disabledItem,
    scrolledY,
    ...props}) => {
    
    function pushDataTime(value) {
        updateDataTime({
            time: value
        })
    }
    const settingsUser = useSelector(state => state.settings.settings)
    const [isActiveNoData, setIsActiveNoData] = useState(false);
    const [isActiveToday, setIsActiveToday] = useState(false);
    const [isActiveTomorrow, setIsActiveTomorrow] = useState(false);
    const [isActiveWeek, setIsActiveWeek] = useState(false);
    const [isActiveMonth, setIsActiveMonth] = useState(false);
    const dateToday = ConverterUTC({method: 'object', settingsUser: settingsUser})
    const [dateToContext, setDateToContext] = useState(datePayload === '' ? '' : datePayload.toLocaleDateString())
   
    function setDateToday() {
        setDateToContext(dateToday.toLocaleDateString())
        updateDataDate({
            date: dateToday.toLocaleDateString()
        })
    }
    function setDateTomorrow() {
        const dateTomorrow = new Date()
        dateTomorrow.setDate(dateTomorrow.getDate() + 1)
        setDateToContext(dateTomorrow.toLocaleDateString())
        updateDataDate({
            date: dateTomorrow.toLocaleDateString()
        })
    }
    
    function setNoDate() {
        setDateToContext(isActiveNoData ? dateToday.toLocaleDateString() : '')
        updateDataDate({date: isActiveNoData ? dateToday.toLocaleDateString() : ''}) 
    }
    
    useEffect(() => {
        const dateTomorrow = new Date()
        dateTomorrow.setDate(dateToday.getDate() + 1)
        if (dateToContext === dateToday.toLocaleDateString()) {
            setIsActiveToday(true);
        } else {
            setIsActiveToday(false);
        }

        if (dateToContext === dateTomorrow.toLocaleDateString()) {
            setIsActiveTomorrow(true);
        } else {
            setIsActiveTomorrow(false);
        }

        if (dateToContext === '') {
            setIsActiveNoData(true);
        } else {
            setIsActiveNoData(false);
        }
        // eslint-disable-next-line
    }, [dateToContext])
    return(
        <div className={active ? 'menu active' : 'menu'} onClick={() => setActive(false)} >
            <div className='calendar__container'
                style={scrolledY === 0 && window.screen.width > 576 ? {top: '-110px', right: '20px'} : calendarPosition ? {top: `${calendarPosition.top}`, 
                                            right: `${calendarPosition.right}`, 
                                            left: `${calendarPosition.left}`,
                                            transform: `${calendarPosition.transform}`} : {top: '15px'}}  
                onClick={e => e.stopPropagation()}
                >
                <ul className='calendar__list'>
                    {
                        statisticsButton && 
                            <ChangePeriodDateButtons isActiveToday={isActiveToday} 
                                isActiveWeek={isActiveWeek} 
                                isActiveMonth={isActiveMonth}
                                setIsActiveToday={setIsActiveToday}
                                setIsActiveWeek={setIsActiveWeek}
                                setIsActiveMonth={setIsActiveMonth} 
                                onChangePeriodOfStatistic={onChangePeriodOfStatistic} /> 
                    }
                    {
                        addTaskButtons && 
                            <AddTaskButtons isActiveToday={isActiveToday}
                                isActiveTomorrow={isActiveTomorrow}
                                disabledItem={disabledItem}
                                isActiveNoData={isActiveNoData}
                                setIsActiveNoData={setIsActiveNoData}
                                setDateToday={setDateToday}
                                setNoDate={setNoDate}
                                setIsActiveToday={setIsActiveToday}
                                setIsActiveTomorrow={setIsActiveTomorrow}
                                setDateTomorrow={setDateTomorrow}/>
                    }
                    {
                        deadlineinput && 
                            <Deadlineinput 
                                onChangeFunc={pushDataTime}
                                showLogo={true}
                            />
                    }
                    {typeOfCalendar === 3 ?
                        <currentDateRangeContext.Provider value={currentRange}>
                            <Calendar updateSelectedDataRange={updateSelectedDataRange} 
                                typeOfCalendar={typeOfCalendar}
                                stylesCalendar={stylesCalendar}
                                stylesCalendarMonthDate={stylesCalendarMonthDate}
                                stylesCalendarNavi={stylesCalendarNavi}
                                stylesCalendarTable={stylesCalendarTable}
                                datePayload={datePayload}/>
                        </currentDateRangeContext.Provider>   
                        :
                        <currentDateContext.Provider value={dateToContext}>
                            <Calendar setDateToContext={setDateToContext}
                                        isActiveToday={isActiveToday}
                                        isActiveTomorrow={isActiveTomorrow} 
                                        updateDataDate={updateDataDate} 
                                        disabledPastDays
                                        setActive={setActive} 
                                        typeOfCalendar={typeOfCalendar}
                                        />
                        </currentDateContext.Provider>
                    }
                    {/* </li> */}
                </ul>
            </div>
        </div>
    )
}

export default DDSelectDate;
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ReactComponent as ClockIcon } from "./static/clock-item.svg";

interface DeadlineInputProp {
    autoFocus?: boolean,
    onChangeFunc: Function,
    showLogo?: boolean,
}


const Deadlineinput = forwardRef((props: DeadlineInputProp, ref: React.Ref<any>) => {
    const [isFocus, setIsFocus] = useState<boolean>(props.autoFocus !== undefined ? props.autoFocus : false)
    const [time, setTime] = useState<string>('')
    function checkWords(e: any) {
        if ("0123456789".indexOf(e.key) !== -1) {
            splitTime(e)
        } else {
            e.preventDefault()
        }
    }

    useImperativeHandle(ref, () => ({
        reset: () => {
            setTime('')
        }
    }))

    function splitTime(e: any) {
        const split_1 = e.target.value.length ? e.target.value.replace(/:/g, '').match(/.{1,2}/g).join(':') : '';
        
        if (split_1.slice(0,2) >= 24) {
            setTime((() => {
                const newState = split_1.replace(split_1.slice(0,2), '23');
                props.onChangeFunc(newState)
                return newState
            }))
        } else if (split_1.slice(3,5) >= 60) {
            setTime(() => {
                const newState = split_1.replace(split_1.slice(3,5), '59');
                props.onChangeFunc(newState);
                return newState
            })
        } else {
            setTime(() => {
                props.onChangeFunc(split_1);
                return split_1
            })
        }
    }

    function hideRemoveBtn() {
        if(time === '') {
            setIsFocus(false)
        } else if (time !== ''){
            if (!time.slice(3,5)) {
                setTime(() => {
                    props.onChangeFunc(time+ ':00')
                    return time+ ':00'
                })
                setIsFocus(false)
            } else if (!time.slice(4,5)) {
                setTime(() => {
                    props.onChangeFunc(time+ '0')
                    return time+ '0'
                })
                setIsFocus(false)
            } else {
                setIsFocus(false)
            }
        }
    }

    function removePushedDataTime() {
        setTime(() => {
            props.onChangeFunc("")
            return ""
        })
    }

    return (
        <li className='calendar__item calendar__item-indent' style={{cursor: 'default'}}>
            <div className='calendar__get-time-from-input'>
                {
                  props.showLogo &&  <ClockIcon />
                }
                <div className={isFocus ? 
                            'calendar__get-time-from-input__field__focused' : 
                            'calendar__get-time-from-input__field'}>
                    <input
                        
                        autoComplete="off"
                        minLength={5}
                        maxLength={5} 
                        type="text" 
                        placeholder={isFocus ? 
                            "  Например, 14:00" : 
                            "Назначить время" } 
                        className={time.length > 0 ? 
                            'calendar__get-time calendar__get-time__shorted' : 
                            'calendar__get-time'} 
                        value={time}
                        onKeyPress={e => checkWords(e)}
                        onChange={e => splitTime(e)}
                        onFocus = {() => setIsFocus(true)}
                        onBlur={() => hideRemoveBtn()}
                        />
                    <span className={time.length > 0 ? 
                            'calendar__remove-btn' : 
                            ''} 
                        onClick={() => removePushedDataTime()}/>
                </div>
            </div>
        </li>
    )
})

export default Deadlineinput;

import "./CalendarDotted.css"
import React from "react"
import { CalendarDottedType } from "./Types/CalendarDottedType"
import { useState } from "react"

interface CalendarDottedProps {
    onSelectedChange?: (selectedWeekDays: CalendarDottedType[]) => void
} 

export default function CalendarDotted({ onSelectedChange }: CalendarDottedProps) {
    const [arrWeek, setArrWeek] = useState<CalendarDottedType[]>([
        {id: 1, name: 'Пн', isSelected: false}, 
        {id: 2, name: 'Вт', isSelected: false}, 
        {id: 3, name: 'Ср', isSelected: false}, 
        {id: 4, name: 'Чт', isSelected: false}, 
        {id: 5, name: 'Пт', isSelected: false}, 
        {id: 6, name: 'Сб', isSelected: false}, 
        {id: 0, name: 'Вс', isSelected: false}
    ])

    const changeWeek = (weekId: number) => {
        setArrWeek((prevState: CalendarDottedType[]) => {
            const newState = prevState.map((weekDay) => 
            weekDay.id === weekId ? {...weekDay, isSelected: !weekDay.isSelected} : weekDay);     
            onSelectedChange && onSelectedChange(newState.filter(day => day.isSelected));
            return newState;
        });
    }

    return (
        <div className='weekDots'>
            <ul className='weekDots__list'>
                {
                    arrWeek.map((el: CalendarDottedType, index: number) => {
                        return (
                            <li key={index}
                                onClick={() => changeWeek(el.id)}  
                                className={`weekDots__item ${el.isSelected && 'selected'}`}>
                                    {el.name}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
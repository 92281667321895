import React, { useEffect, useRef, useState } from "react";
import { CustomListItems } from "../../Types/Types";
import './CustomList.css'

interface CustomListProps {
    list: CustomListItems[],
    hide?: boolean,
    listName?: string,
    onChangeFunc: Function,
    listVariant: 'radio' | 'checkbox'
    defaultCheck?: number
}
export default function CustomList( { list, hide, listName, listVariant, onChangeFunc, defaultCheck }: CustomListProps) {
    const [isSelected, setIsSelected] = useState<string | null>(null)
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const elemRefs = useRef<{ [key: string]: HTMLDivElement | null}> ({});

    useEffect(() => {
        if (isSelected && elemRefs.current[isSelected]) {
            elemRefs.current[isSelected]?.childNodes.forEach((node: ChildNode) => {
                if (node.nodeName === 'INPUT') {
                    const nodeInput: HTMLInputElement = node as HTMLInputElement;
                    nodeInput.focus()
                } else if (node.nodeName === 'DIV') {
                    node.childNodes.forEach((node: ChildNode) => {
                        const nodeDiv: HTMLDivElement = node as HTMLDivElement;    
                        nodeDiv.focus()
                    })
                }
            })
        }
    }, [isSelected]);

    const handleChangeRadio = (id: string, index: number) => {
        onChangeFunc(index)
        setIsSelected(id)
    };

    useEffect(() => {
        return () => {

        }
    })

    const handleChangeCheckbox = (id: string) => {
        onChangeFunc(!isChecked)
        setIsSelected(id)
        setIsChecked(!isChecked)
    }

    const handleOnClick = (node: HTMLDivElement) => {
        const findedInput = node.parentElement?.querySelector(`input[type=${listVariant}]`) as HTMLInputElement
        if (findedInput) {
            findedInput.checked = true
        }        
    }
    
    return (
        <div className="customList" style={hide ? {display: 'none'} : {display: 'flex'}}>
            { 
                list.map((elem, index) => {
                    return (
                        <div key={index} className="customList__item">
                            <label htmlFor={elem.id} style={{display: "flex", flexDirection: 'row', alignItems: 'center', gap: '12px', userSelect: 'none'}}>
                                <input type={listVariant}
                                    defaultChecked={defaultCheck === index ?? false}
                                    className="customList__item-input"
                                    onChange={() => {listVariant === 'radio' ? handleChangeRadio(elem.id, index) : handleChangeCheckbox(elem.id)}} 
                                    id={elem.id} 
                                    name={listVariant === 'radio' ? listName ?? 'list' : elem.description}/>
                                    {
                                        listVariant === 'checkbox' && 
                                        <span onChange={() => handleChangeCheckbox(elem.id)} className="customChecklist-item" />
                                    }
                                <p>{elem.description}</p>
                            </label>
                            {
                                elem.element ?
                                <div ref={(el) => { elemRefs.current[elem.id] = el }}
                                    tabIndex={0}
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) => handleOnClick(e.currentTarget)}
                                    className="customList__input-container">
                                        {elem.element}
                                </div> : null
                            }
                        </div>
                    )
                }) 
            }
        </div>
    )
}				        
import request from "../../Requests/requests"
import { Dispatch } from "redux";
import { getRepeatTemplates } from "./getRepeatTemplates";
import { TaskPost } from "../../Types/Types";

export function addRepeatTemplate(payload: TaskPost) {
    return async (dispatch: Dispatch) => {
        await request.post('/RepitTemplate/', {
            userId: localStorage.getItem('token'),
            title: payload.title,
            description: payload.description,
            date: payload.date,
            time: payload.time ? payload.time : null,
            priority: payload.priority,
            hours: payload.hours,
            minutes: payload.minutes,
            tagsId: payload.tagsId ? payload.tagsId : null,
            repitPeriod: payload.repitPeriod,
            templateTime: payload.templateTime ?? '',
            intervalType: payload.intervalType ?? 1,
            interval: payload.interval ?? 0,
            selectedWeekDays: payload.selectedWeekDays ?? null,
            selectedMonthDays: payload.selectedMonthDays ?? null,
            endType: payload.endType ?? 1,
            dateEnd: payload.dateEnd ?? '',
            repitCount: payload.repitCount ?? 0,
            isCreatedAfterCompited: payload.isCreatedAfterCompited ?? false
        })
        .then(() => dispatch<any>(getRepeatTemplates()))
    }
}
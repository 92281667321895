import React, { useEffect, useState } from "react";
import './ChangePeriodDateButtons.css';
import {ReactComponent as CalendarIcon} from "../AddTaskButtons/static/calendar-item.svg";

interface ChangePeriodDateButtonsProps {
    onChangePeriod: Function,
    buttonsVariant: 'statistic' | 'addTask',
    currentDate: Date,
    pickedPeriod: string
    
}


const ChangePeriodDateButtons = ({onChangePeriod,  buttonsVariant, currentDate, pickedPeriod}: ChangePeriodDateButtonsProps) => {
    const [activePeriod, setActivePreiod] = useState<string>(pickedPeriod)
    const arrPeriods: {period: string, id: string}[] = buttonsVariant === 'statistic' ? [ {period: 'сегодня', id: 'onToday'}, 
                                                        {period: 'неделя', id: 'onWeek'}, 
                                                        {period: 'месяц', id: 'onMonth'}] : [{period: 'Сегодня', id: 'today'}, 
                                                        {period: 'Завтра', id: 'tomorrow'}, 
                                                        {period: 'Без срока', id: 'no_date'}]
    
    const getStartMonth = new Date(currentDate?.getFullYear(), currentDate?.getMonth());
    const getStartOfWeek = () => {
        const startWeek = new Date()  
        startWeek.setDate(currentDate?.getDate() - currentDate?.getDay() + (currentDate?.getDay() === 0 ? -6 : 1));
        return (startWeek);
    }


    

    const handleChange = (value: string) => {
        setActivePreiod(value)
        if (buttonsVariant === 'addTask') {
            switch (value) {
                case 'today':
                    onChangePeriod({date: currentDate, period: 'today'})
                    break;
                case 'tomorrow':
                    const dateTomorrow = new Date()
                    dateTomorrow.setDate(currentDate.getDate() + 1)
                    onChangePeriod({date: dateTomorrow, period: 'tomorrow'})
                    break;
                case 'no_date':
                    onChangePeriod({date: null, period: 'no_date'})
                    break;
                default:
                    break;
            }
        }
        if (buttonsVariant === 'statistic') {
            switch (value) {
                case 'onToday':
                    onChangePeriod({arr: [new Date(), new Date()], period: value})
                    break;
                case 'onWeek':
                    onChangePeriod({arr: [getStartOfWeek(), currentDate], period: value})
                    break;
                case 'onMonth':
                    onChangePeriod({arr: [getStartMonth, currentDate], period: value})
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        setActivePreiod(pickedPeriod)
        // eslint-disable-next-line
    }, [pickedPeriod])

    return (
        <>
        <div style={{display: 'flex', gap: '16px', alignItems: 'flex-end'}}>
            <CalendarIcon className="buttons-group__logo"/>
            <div className="buttons-group">
                {
                    arrPeriods.map((el, index) => {
                        return (
                            <span id={el.id}
                                key={index} 
                                className={`buttons-group__element ${el.id === activePeriod ? 'activePeriod' : ''}`}
                                onClick={() => handleChange(el.id)}>
                                {el.period}
                            </span>
                        )
                    })
                }
            </div>
        </div>
        </>
    )
}

export default ChangePeriodDateButtons;
import React, { ReactElement, useEffect, useRef, useState } from "react";
import './Button.css'

interface ButtonProps {
    logo?: ReactElement,
    title?: string,
    btnType?: 'danger' | 'filled' | 'default' | 'hidden'
    btnSize?: 'base' | 'xs' | 'xl' | 'lg' | 'adaptive'
    stylesContainer?: React.CSSProperties,
    stylesLogo?: React.CSSProperties,
    stylesTitle?: React.CSSProperties,
    loading?: boolean,
    disabled?: boolean,
    onClickFunc?: Function,
    showPicked?: boolean,
}

const Button = ({logo, title, btnType, btnSize, stylesContainer, stylesLogo, stylesTitle, loading, disabled, onClickFunc, showPicked=false}: ButtonProps) => {
    const [widthOfContent, setWidthOfContent] = useState<number>();
    const btnRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        const updateWidth = () => {
            if (btnRef.current) {
              setWidthOfContent(btnRef.current.offsetWidth);
            }
          };
      
          updateWidth();      
          window.addEventListener('resize', updateWidth);
      
          return () => {
            window.removeEventListener('resize', updateWidth);
          };
    })

    return (
        <button ref={btnRef} className={`button ${btnType ?? 'default'} ${btnSize ?? 'base'} ${showPicked ? 'picked' : null}`} 
            style={loading ? {width: `${widthOfContent}px`} : {} }
            onClick={(e: any) => { e.stopPropagation() 
                                    e.preventDefault()
                                    onClickFunc && onClickFunc()}} 
            disabled={disabled}>
            {loading ? 
                <div className="loaderBtn" /> :
                <span className="button__container" style={stylesContainer}>
                    <span className="button__logo" style={stylesLogo}>{logo}</span>
                    <p className="button__title" style={stylesTitle}>{title ?? 'Кнопка'}</p>
                </span>
            }   
        </button>
    )

}

export default Button;